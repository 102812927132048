import { createGtm } from "@gtm-support/vue-gtm";

const getCookiePreference = () => {
  const cookiePreference = localStorage.getItem("COOKIE_PREFERENCE");

  if(!cookiePreference) {
    return;
  }

  return JSON.parse(cookiePreference)
}

export default defineNuxtPlugin((nuxtApp) => {
  window.STARTUP_GTM = () => {
    if(typeof(document) === 'undefined') {
      return
    }
  
    const cookiePref = getCookiePreference()
  
    if(!cookiePref) {
      return;
    }
  
  
    if(!cookiePref.marketing) {
      return
    }
  
    if (!useRuntimeConfig().public.gtm.id) return;
    nuxtApp.vueApp.use(
      createGtm({
        id: useRuntimeConfig().public.gtm.id,
        defer: false,
        compatibility: false,
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false,
      }),
    );
  }

  window.STARTUP_GTM();
});
